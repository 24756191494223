export function sendEmail() {
  const emailAddress = "npaul2173@gmail.com";
  const subject = "Hello from My Website";
  const body = "I hope this email finds you well.\n\nBest regards, Your Name";

  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoLink;
}

const sayHelloButton = document.getElementById("sayHelloButton");

sayHelloButton.addEventListener("click", sendEmail);
